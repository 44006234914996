.App {
  text-align: center;
}

@media (max-width: 640px) {
  .my-boxes {
    left: 41%;
    top: 50%;
  }
  .my-box1{
    height: 400px;
  }
  .my-box11{
    height: 300px;
  }
  .my-boxes2{
    height: 450px;
  }
  .my-boxes22{
    height: 300px;
  }
  .my-circle{
    left: 41%;
    top: 53%;
  }
  .homeswap-1{
    height: 560px;
  }
  .homeswap-2{
    height: 500px;
  }
  .homeswap-3{
    left: 38%;
    top: 46%;
  }
  .swap-home{
    height: 400px;
  }
  .swap-home1{
    height: 400px;
  }
  .swap-circle-1{
    top: 45%;
    left: 42%;
  }
}
@media (max-width: 500px) {
  .my-boxes {
    left: 38%;
    top: 50%;
  }
  .my-box1{
    height: 500px;
  }
  .my-box11{
    height: 400px;
  }
  .my-boxes2{
    height: 560px;
  }
  .my-boxes22{
    height: 350px;
  }
  .my-circle{
    left: 39%;
    top: 55%;
  }
  .homeswap-1{
    height: 660px;
  }
  .homeswap-2{
    height: 600px;
  }
  .homeswap-3{
    left: 38%;
    top: 48%;
  }
  .swap-home{
    height: 450px;
  }
  .swap-home1{
    height: 450px;
  }
  .swap-circle-1{
    top: 45%;
    left: 38%;
  }
}
@media (max-width:450px) {
  .homeswap-1{
    height: 400px;
  }
  .homeswap-2 {
    height: 365px;
  }
  .homeswap-3 {
    left: 37%;
    top: 46%;
  }
  .swap-home {
    height: 250px;
  }
  .swap-home1 {
    height: 320px;
  }
  .swap-circle-1{
    top: 38%;
    left: 38%;
  }
}
@media (max-width: 400px) {
  .my-boxes {
    left: 35%;
    top: 50%;
  }
  .my-box1{
    height: 600px;
  }
  .my-box11{
    height: 500px;
  }
  .my-boxes2{
    height: 660px;
  }
  .my-boxes22{
    height: 400px;
  }
  .my-circle{
    left: 35%;
    top: 57%;
  }
  .homeswap-1{
    height: 450px;
  }
  .homeswap-2{
    height: 500px;
  }
  .homeswap-3{
    left: 36%;
    top: 43%;
  }
  .swap-home{
    height: 350px;
  }
  .swap-home1{
    height: 370px;
  }
  .swap-circle-1{
    top: 43%;
    left: 35%;
  }
}
@media (max-width: 360px) {
  .my-boxes {
    left: 33%;
    top: 54%;
  }
  .my-box1{
    height: 700px;
  }
  .my-boxes2{
    height: 750px;
  }
  .my-boxes22{
    height: 450px;
  }
  .my-circle{
    left: 32%;
    top: 58%;
  }
  .homeswap-1{
    height: 650px;
  }
  .homeswap-2{
    height: 680px;
  }
  .homeswap-3{
    left: 34%;
    top: 47%;
  }
  .swap-home{
    height: 380px;
  }
  .swap-home1{
    height: 490px;
  }
  .swap-circle-1{
    top: 42%;
    left: 33%;
  }
}
@media (max-width: 320px) {
  .my-boxes {
    left: 30%;
    top: 58%;
  }
  .homeswap-3{
    left: 30%;
    top: 45.5%;
  }
  .swap-circle-1{
    top: 40%;
    left: 31%;
  }
}
